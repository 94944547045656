var render = function render(){var _vm=this,_c=_vm._self._c;return _c('z-section-divider',{attrs:{"title":_vm.$t('guest.document'),"start-opened":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"documents-section"},[_c('z-select',{attrs:{"data-cy":"document-type","item-value":"value","item-text":"text","disabled":_vm.disabled,"items":_vm.documentTypes,"value":_vm.guest.documents.documentTypeId,"label":_vm.$t('guest.document_type'),"error-messages":_vm.getErrorMessages(_vm.v$.documents.documentTypeId)},on:{"change":function($event){return _vm.$emit('change', {
            'documents.documentTypeId': $event,
            'documents.documentNumber': '',
          })}}}),(_vm.documentMask)?_c('z-input',{key:"document-with-mask",staticStyle:{"width":"100%"},attrs:{"type":"text","data-cy":"document-number","disabled":_vm.disabled,"value":_vm.guest.documents.documentNumber,"mask":_vm.documentMask,"label":_vm.$t('guest.document_number'),"error-messages":_vm.getErrorMessages(_vm.v$.documents.documentNumber)},on:{"input":function($event){return _vm.$emit('change', { 'documents.documentNumber': $event })}}}):_c('z-input',{key:"document-without-mask",staticStyle:{"width":"100%"},attrs:{"type":"text","data-cy":"document-number","disabled":_vm.disabled,"value":_vm.guest.documents.documentNumber,"label":_vm.$t('guest.document_number'),"error-messages":_vm.getErrorMessages(_vm.v$.documents.documentNumber)},on:{"input":function($event){return _vm.$emit('change', { 'documents.documentNumber': $event })}}})],1),(_vm.showDocScan('mainDocument'))?[_c('div',{staticClass:"d-flex justify-start mb-3 flex-wrap gap-5"},[(_vm.guest.documents.front)?_c('uploaded-card',{attrs:{"data-cy":"front-picture-card","image-source":_vm.guest.documentTypeId !== 0
              ? 'documento_frente.jpg'
              : 'certidao_nascimento.jpg',"image":_vm.guest.documents.frontImageMemory || _vm.guest.documents.front,"error-messages":_vm.getErrorMessages(_vm.v$.documents.frontImage),"enable-close":!_vm.disableDocumentUpload ? true : false},on:{"click:erase":_vm.eraseFrontDocumentsImage}}):_vm._e(),(_vm.guest.documents.back)?_c('uploaded-card',{attrs:{"data-cy":"back-picture-card","image-source":_vm.guest.documentTypeId !== 0
              ? 'documento_verso.jpg'
              : 'autorizacao_viagem.jpg',"image":_vm.guest.documents.backImageMemory || _vm.guest.documents.back,"error-messages":_vm.getErrorMessages(_vm.v$.documents.backImage),"enable-close":!_vm.disableDocumentUpload ? true : false},on:{"click:erase":_vm.eraseBackDocumentsImage}}):_vm._e()],1)]:_vm._e(),(_vm.showDocScan('mainDocument') && !_vm.disableDocumentUpload)?[(!_vm.disabled && (!_vm.guest.documents.front || !_vm.guest.documents.back))?_c('z-btn',{staticStyle:{"grid-column":"1 / span 1"},attrs:{"data-cy":"button-scan-document","primary":"","text":_vm.$t('guest.scan_document'),"icon":"$add_rounded"},on:{"click":function($event){_vm.$emit('click-scan-document'), _vm.setAdditionalDocument(false)}}}):(!_vm.disabled)?_c('z-btn',{staticStyle:{"grid-column":"1 / span 1"},attrs:{"data-cy":"button-upload-document-again","width":"300px","text":_vm.$t('guest.scan_document_again'),"icon":"$add_rounded"},on:{"click":function($event){_vm.$emit('click-scan-document'), _vm.setAdditionalDocument(false)}}}):_vm._e(),_c('div',{staticClass:"error--container"},[_c('p',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.getErrorMessages(_vm.v$.documents.front).length > 0 ? _vm.getErrorMessages(_vm.v$.documents.front)[0] : "")+" ")])])]:_vm._e(),(
        _vm.showDocScan('additionalDocument') ||
        _vm.config.documentData?.additionalDocument?.rules.required
      )?[_c('p',{staticClass:"mb-5 body-bold",staticStyle:{"color":"rgba(0, 0, 0, 0.6)"}},[_vm._v(" "+_vm._s(_vm.$t("guest.document_additional"))+" ")]),_c('div',{staticClass:"documents-section"},[(_vm.additionalDocumentTypes.length)?[_c('z-autocomplete',{attrs:{"data-cy":"document-type","item-value":"value","item-text":"text","disabled":_vm.disabled,"items":_vm.additionalDocumentTypes,"value":_vm.guest.additionalDocument.documentTypeId,"label":_vm.$t('guest.document_type'),"error-messages":_vm.getErrorMessages(_vm.v$.additionalDocument.documentTypeId)},on:{"change":function($event){return _vm.$emit('change', {
                'additionalDocument.documentTypeId': $event,
              })}}}),(_vm.additionalDocumentMask)?_c('z-input',{key:"document-with-mask",staticStyle:{"width":"100%"},attrs:{"type":"text","data-cy":"document-number","disabled":_vm.disabled,"value":_vm.guest.additionalDocument.documentNumber,"mask":_vm.additionalDocumentMask,"label":_vm.$t('guest.document_number'),"error-messages":_vm.getErrorMessages(_vm.v$.additionalDocument.documentNumber)},on:{"input":function($event){return _vm.$emit('change', { 'additionalDocument.documentNumber': $event })}}}):_c('z-input',{key:"document-without-mask",staticStyle:{"width":"100%"},attrs:{"type":"text","data-cy":"document-number","disabled":_vm.disabled,"value":_vm.guest.additionalDocument.documentNumber,"label":_vm.$t('guest.document_number'),"error-messages":_vm.getErrorMessages(_vm.v$.additionalDocument.documentNumber)},on:{"input":function($event){return _vm.$emit('change', { 'additionalDocument.documentNumber': $event })}}})]:_vm._e(),(_vm.showDocScan('additionalDocument'))?[_c('div',{staticClass:"d-flex flex-wrap"},[(_vm.guest.additionalDocument.front)?_c('uploaded-card',{staticClass:"align-self-start mx-2",attrs:{"data-cy":"front-picture-card","image-source":_vm.guest.additionalDocument.documentTypeId !== 0
                  ? 'documento_frente.jpg'
                  : 'certidao_nascimento.jpg',"image":_vm.guest.additionalDocument.frontImageMemory ||
                _vm.guest.additionalDocument.front,"error-messages":_vm.getErrorMessages(_vm.v$.additionalDocument.front),"enable-close":!_vm.disableDocumentUpload ? true : false},on:{"click:erase":_vm.eraseAddFrontDocumentsImage}}):_vm._e(),(_vm.guest.additionalDocument.back)?_c('uploaded-card',{staticClass:"align-self-start",attrs:{"data-cy":"back-picture-card","image-source":_vm.guest.additionalDocument.documentTypeId !== 0
                  ? 'documento_verso.jpg'
                  : 'autorizacao_viagem.jpg',"image":_vm.guest.additionalDocument.backImageMemory ||
                _vm.guest.additionalDocument.back,"error-messages":_vm.getErrorMessages(_vm.v$.additionalDocument.back),"enable-close":!_vm.disableDocumentUpload ? true : false},on:{"click:erase":_vm.eraseAddBackDocumentsImage}}):_vm._e()],1)]:_vm._e(),(_vm.showDocScan('additionalDocument') && !_vm.disableDocumentUpload)?[(
              !_vm.disabled &&
              (!_vm.guest.additionalDocument.front ||
                !_vm.guest.additionalDocument.back)
            )?_c('z-btn',{staticStyle:{"grid-column":"1 / span 1"},attrs:{"data-cy":"button-scan-document","primary":"","text":_vm.$t('guest.scan_document_add'),"icon":"$add_rounded"},on:{"click":_vm.onClickScan}}):(!_vm.disabled)?_c('z-btn',{staticStyle:{"grid-column":"1 / span 1"},attrs:{"data-cy":"button-upload-document-again","width":"300px","text":_vm.$t('guest.scan_document_again'),"icon":"$add_rounded"},on:{"click":_vm.onClickScan}}):_vm._e(),_c('div',{staticClass:"error--container"},[_c('p',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.getErrorMessages(_vm.v$.additionalDocument.front).length > 0 ? _vm.getErrorMessages(_vm.v$.additionalDocument.front)[0] : "")+" ")])])]:_vm._e()],2)]:_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }