<template>
  <z-section-divider :title="$t('guest.document')" start-opened>
    <template #content>
      <div class="documents-section">
        <z-select
          data-cy="document-type"
          item-value="value"
          item-text="text"
          :disabled="disabled"
          :items="documentTypes"
          :value="guest.documents.documentTypeId"
          :label="$t('guest.document_type')"
          :error-messages="getErrorMessages(v$.documents.documentTypeId)"
          @change="
            $emit('change', {
              'documents.documentTypeId': $event,
              'documents.documentNumber': '',
            })
          "
        />

        <z-input
          v-if="documentMask"
          key="document-with-mask"
          type="text"
          data-cy="document-number"
          style="width: 100%"
          :disabled="disabled"
          :value="guest.documents.documentNumber"
          :mask="documentMask"
          :label="$t('guest.document_number')"
          :error-messages="getErrorMessages(v$.documents.documentNumber)"
          @input="$emit('change', { 'documents.documentNumber': $event })"
        />

        <z-input
          v-else
          key="document-without-mask"
          type="text"
          data-cy="document-number"
          style="width: 100%"
          :disabled="disabled"
          :value="guest.documents.documentNumber"
          :label="$t('guest.document_number')"
          :error-messages="getErrorMessages(v$.documents.documentNumber)"
          @input="$emit('change', { 'documents.documentNumber': $event })"
        />
      </div>

      <template v-if="showDocScan('mainDocument')">
        <div class="d-flex justify-start mb-3 flex-wrap gap-5">
          <uploaded-card
            v-if="guest.documents.front"
            data-cy="front-picture-card"
            :image-source="
              guest.documentTypeId !== 0
                ? 'documento_frente.jpg'
                : 'certidao_nascimento.jpg'
            "
            :image="guest.documents.frontImageMemory || guest.documents.front"
            :error-messages="getErrorMessages(v$.documents.frontImage)"
            :enable-close="!disableDocumentUpload ? true : false"
            @click:erase="eraseFrontDocumentsImage"
          />
          <uploaded-card
            v-if="guest.documents.back"
            data-cy="back-picture-card"
            :image-source="
              guest.documentTypeId !== 0
                ? 'documento_verso.jpg'
                : 'autorizacao_viagem.jpg'
            "
            :image="guest.documents.backImageMemory || guest.documents.back"
            :error-messages="getErrorMessages(v$.documents.backImage)"
            :enable-close="!disableDocumentUpload ? true : false"
            @click:erase="eraseBackDocumentsImage"
          />
        </div>
      </template>

      <template v-if="showDocScan('mainDocument') && !disableDocumentUpload">
        <z-btn
          v-if="!disabled && (!guest.documents.front || !guest.documents.back)"
          data-cy="button-scan-document"
          primary
          :text="$t('guest.scan_document')"
          style="grid-column: 1 / span 1"
          icon="$add_rounded"
          @click="$emit('click-scan-document'), setAdditionalDocument(false)"
        />
        <z-btn
          v-else-if="!disabled"
          data-cy="button-upload-document-again"
          width="300px"
          style="grid-column: 1 / span 1"
          :text="$t('guest.scan_document_again')"
          icon="$add_rounded"
          @click="$emit('click-scan-document'), setAdditionalDocument(false)"
        />
        <div class="error--container">
          <p class="error--text">
            {{
              getErrorMessages(v$.documents.front).length > 0
                ? getErrorMessages(v$.documents.front)[0]
                : ""
            }}
          </p>
        </div>
      </template>

      <template
        v-if="
          showDocScan('additionalDocument') ||
          config.documentData?.additionalDocument?.rules.required
        "
      >
        <p class="mb-5 body-bold" style="color: rgba(0, 0, 0, 0.6)">
          {{ $t("guest.document_additional") }}
        </p>

        <div class="documents-section">
          <template v-if="additionalDocumentTypes.length">
            <z-autocomplete
              data-cy="document-type"
              item-value="value"
              item-text="text"
              :disabled="disabled"
              :items="additionalDocumentTypes"
              :value="guest.additionalDocument.documentTypeId"
              :label="$t('guest.document_type')"
              :error-messages="
                getErrorMessages(v$.additionalDocument.documentTypeId)
              "
              @change="
                $emit('change', {
                  'additionalDocument.documentTypeId': $event,
                })
              "
            />

            <z-input
              v-if="additionalDocumentMask"
              key="document-with-mask"
              type="text"
              data-cy="document-number"
              :disabled="disabled"
              :value="guest.additionalDocument.documentNumber"
              style="width: 100%"
              :mask="additionalDocumentMask"
              :label="$t('guest.document_number')"
              :error-messages="
                getErrorMessages(v$.additionalDocument.documentNumber)
              "
              @input="
                $emit('change', { 'additionalDocument.documentNumber': $event })
              "
            />
            <z-input
              v-else
              key="document-without-mask"
              type="text"
              data-cy="document-number"
              :disabled="disabled"
              :value="guest.additionalDocument.documentNumber"
              style="width: 100%"
              :label="$t('guest.document_number')"
              :error-messages="
                getErrorMessages(v$.additionalDocument.documentNumber)
              "
              @input="
                $emit('change', { 'additionalDocument.documentNumber': $event })
              "
            />
          </template>

          <template v-if="showDocScan('additionalDocument')">
            <div class="d-flex flex-wrap">
              <uploaded-card
                v-if="guest.additionalDocument.front"
                data-cy="front-picture-card"
                class="align-self-start mx-2"
                :image-source="
                  guest.additionalDocument.documentTypeId !== 0
                    ? 'documento_frente.jpg'
                    : 'certidao_nascimento.jpg'
                "
                :image="
                  guest.additionalDocument.frontImageMemory ||
                  guest.additionalDocument.front
                "
                :error-messages="getErrorMessages(v$.additionalDocument.front)"
                :enable-close="!disableDocumentUpload ? true : false"
                @click:erase="eraseAddFrontDocumentsImage"
              />
              <uploaded-card
                v-if="guest.additionalDocument.back"
                data-cy="back-picture-card"
                class="align-self-start"
                :image-source="
                  guest.additionalDocument.documentTypeId !== 0
                    ? 'documento_verso.jpg'
                    : 'autorizacao_viagem.jpg'
                "
                :image="
                  guest.additionalDocument.backImageMemory ||
                  guest.additionalDocument.back
                "
                :error-messages="getErrorMessages(v$.additionalDocument.back)"
                :enable-close="!disableDocumentUpload ? true : false"
                @click:erase="eraseAddBackDocumentsImage"
              />
            </div>
          </template>

          <template
            v-if="showDocScan('additionalDocument') && !disableDocumentUpload"
          >
            <z-btn
              v-if="
                !disabled &&
                (!guest.additionalDocument.front ||
                  !guest.additionalDocument.back)
              "
              data-cy="button-scan-document"
              primary
              :text="$t('guest.scan_document_add')"
              style="grid-column: 1 / span 1"
              icon="$add_rounded"
              @click="onClickScan"
            />
            <z-btn
              v-else-if="!disabled"
              data-cy="button-upload-document-again"
              width="300px"
              style="grid-column: 1 / span 1"
              :text="$t('guest.scan_document_again')"
              icon="$add_rounded"
              @click="onClickScan"
            />
            <div class="error--container">
              <p class="error--text">
                {{
                  getErrorMessages(v$.additionalDocument.front).length > 0
                    ? getErrorMessages(v$.additionalDocument.front)[0]
                    : ""
                }}
              </p>
            </div>
          </template>
        </div>
      </template>
    </template>
  </z-section-divider>
</template>

<script>
import { useNamespacedGetters } from "vuex-composition-helpers/dist"

import { useDocuments } from "../../../useDocuments"

export default {
  props: {
    guest: {
      type: Object,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    v$: {
      type: Object,
      required: true,
    },
    getErrorMessages: {
      type: Function,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disableDocumentUpload: {
      type: Boolean,
      default: false,
    },
  },
  // eslint-disable-next-line max-lines-per-function
  setup(props, { emit }) {
    const {
      documentTypes,
      documentMask,
      additionalDocumentTypes,
      additionalDocumentMask,
      setAdditionalDocument,
      eraseFrontDocumentsImage: eraseFront,
      eraseBackDocumentsImage: eraseBack,
    } = useDocuments()

    const { getDocumentosScanEnableds } = useNamespacedGetters("parameters", [
      "getDocumentosScanEnableds",
    ])

    const onClickScan = () => {
      emit("click-scan-document", true)
      setAdditionalDocument(true)
    }

    const showDocScan = (docType) => {
      const { minorsGuests, foreignersGuests } =
        getDocumentosScanEnableds.value[docType]

      if (props.config.documentData?.[docType]?.rules?.documentUpload) {
        return true
      }

      if (props.config.documentData?.[docType]?.rules?.hidden == false) {
        return true
      }

      if (
        props.guest.nationalityId != null &&
        props.guest.nationalityId != "BR" &&
        foreignersGuests
      ) {
        return true
      }

      if (props.guest.isChild && minorsGuests) {
        return true
      }

      return false
    }

    // main document
    const eraseFrontDocumentsImage = () => {
      setAdditionalDocument(false)
      eraseFront()
    }

    const eraseBackDocumentsImage = () => {
      setAdditionalDocument(false)
      eraseBack()
    }

    // additional document
    const eraseAddFrontDocumentsImage = () => {
      setAdditionalDocument(true)
      eraseFront()
    }
    const eraseAddBackDocumentsImage = () => {
      setAdditionalDocument(true)
      eraseBack()
    }

    return {
      documentTypes,
      documentMask,
      additionalDocumentTypes,
      additionalDocumentMask,
      setAdditionalDocument,

      onClickScan,
      eraseBackDocumentsImage,
      eraseFrontDocumentsImage,
      eraseAddFrontDocumentsImage,
      eraseAddBackDocumentsImage,
      showDocScan,
    }
  },
}
</script>

<style lang="scss" scoped>
.documents-section {
  display: flex;
  column-gap: 12px;
  max-width: 600px;
  width: 100%;
}
.error--text {
  font-size: 0.75rem;
  padding: 0 12px;
}

.error--container {
  width: 100%;
  height: 26px !important;
  grid-column: 1 / span 1;
  margin-top: 4px;
}
</style>
